.subheader {
  text-align: center;
  padding: 10px;
}

.subheader__one {
  font-size: 25px;
  margin-top: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 300;
}

.subheader__two {
  font-size: 15px;
  margin-top: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 300;
}
