.header {
	padding: 5px 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-transform: uppercase;
}

.header__main {
	font-size: 35px;
}

.header__nav {
	font-size: 20px;
	display: flex;
}

.header__link {
	text-decoration: none;
	margin-right: 15px;
	color: black;
}

@media only screen and (max-width: 600px) {
	.header {
		padding: 5px 15px;
	}

	.header__nav {
		flex-direction: column;
	}
}

@media only screen and (min-width: 1000px) {
	.header {
		padding: 5px 40px;
	}
}
