.books__list {
	margin-top: 10px;
	padding: 20px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	animation: fadeInAnimation ease 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.books__link {
	text-decoration: none;
	color: grey;
}

.books__container {
	margin: 25px;
}

.books__container:hover {
	cursor: pointer;
}

.books__cover {
	height: 230px;
	width: 230px;
	border-radius: 5px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.books__cover:hover {
	opacity: 0.5;
}

.books__details {
	text-transform: uppercase;
	font-weight: 400;
	font-size: 18;
	margin-top: 5px;
	width: 230px;
}
